import { Box, Divider, Stack, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import Section from 'components/section/Section';
import currency from 'currency.js';
import { format } from 'date-fns';
import { ReactNode } from 'react';
import { formatPhoneNumber, parseDate } from 'utils';
import AppointmentStatusChip from './AppointmentStatusChip';
import { Appointment } from 'types';

const parseCallDate = (date: string) => new Date(date);

const CardItem = ({ label, value }: { label: ReactNode; value: ReactNode }) => (
  <Stack flex={1} gap="21px">
    <Typography variant="subtitle2">{label}</Typography>
    <Typography variant="h2">{value}</Typography>
  </Stack>
);

const AppointmentDetailsHeader = ({ appointmentDetails }: { appointmentDetails: Appointment }) => {
  const [lastCall] = appointmentDetails.call_history.sort((a, b) => parseCallDate(b.call_time_utc).getTime() - parseCallDate(a.call_time_utc).getTime());
  return (
    <Section sx={{ px: '40px' }}>
      <Stack gap="40px">
        <Stack
          direction="row"
          gap="35px"
          sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
        >
          <CardItem label="Driver name" value={appointmentDetails.driverFullName} />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          />
          <CardItem
            label={
              <Box display="flex" gap={2}>
                Appointment date{' '}
                {appointmentDetails.appointment_status === 'pending' ? (
                  <AppointmentStatusChip
                    dueDate={appointmentDetails.appointment_time}
                    status={appointmentDetails.appointment_status}
                  />
                ) : null}
              </Box>
            }
            value={
              appointmentDetails.appointment_time
                ? format(parseDate(appointmentDetails.appointment_time), 'MMMM dd, yyyy')
                : 'Not scheduled'
            }
          />
        </Stack>
        <Stack
          direction="row"
          gap="35px"
          sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
        >
          <CardItem
            label="Driver phone number"
            value={
              <Box component="span" sx={{ color: 'secondary.dark' }}>
                {formatPhoneNumber(appointmentDetails.driverPhoneNumber)}
              </Box>
            }
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: 'none', sm: 'flex' } }}
          />
          <CardItem
            label="Service fee"
            value={
              <Box
                component="div"
                display="flex"
                alignItems="baseline"
                sx={{ color: green['A700'] }}
              >
                <Typography variant="h4">$</Typography>
                {currency(appointmentDetails.service_fee, { symbol: '' }).format()}
              </Box>
            }
          />
        </Stack>
        {lastCall ? (
          <Stack sx={{ background: '#F5F5F5', p: 3 }} gap="21px">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2">
                <strong>Resolution summary</strong>
              </Typography>
              <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                {format(parseCallDate(lastCall.call_time_utc), 'MMMM dd, yyyy')}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {lastCall.call_summary}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </Section>
  );
};

export default AppointmentDetailsHeader;