import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MedicalServicesOutlined } from '@mui/icons-material';
import { API_URL } from '../../constants';
import { useAppointments } from 'providers/AppointmentsProvider';
import { useForm, Controller } from 'react-hook-form';
import currency from 'currency.js';
import { format } from 'date-fns';

interface FormData {
  driverFullName: string;
  driverPhoneNumber: string;
  serviceName: string;
  service_fee: string;
  rebate_percentage: string;
  firstTimeRange: Date | null;
  secondTimeRange: Date | null;
  companyName: string;
  supplierShopName: string;
  vehicleColor: string;
  vehicleYear: string;
  vehicleMake: string;
  vehicleModel: string;
  vehiclePlate: string;
  vehicleCustomization: string;
  to_phone_number: string;
}

function alignToNearest30Minutes(date: Date) {
  const minutes = date.getMinutes();
  const alignedMinutes = minutes < 30 ? 0 : 30;
  date.setMinutes(alignedMinutes, 0, 0);
  return date;
}

function OverviewBar() {
  const [open, setOpen] = useState(false);
  const { refreshAppointments } = useAppointments();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      driverFullName: 'John Doe',
      driverPhoneNumber: '',
      serviceName: 'Oil Change',
      service_fee: '500.00',
      rebate_percentage: '10.00',
      firstTimeRange: alignToNearest30Minutes(new Date()),
      secondTimeRange: alignToNearest30Minutes(new Date(new Date().setDate(new Date().getDate() + 1))),
      companyName: 'AutoFix Inc.',
      supplierShopName: 'AutoFix Shop',
      vehicleColor: 'Red',
      vehicleYear: '2020',
      vehicleMake: 'Toyota',
      vehicleModel: 'Camry',
      vehiclePlate: 'ABC123',
      vehicleCustomization: 'The vehicle weighs 2 tonnes.',
      to_phone_number: '',
    },
  });

  const handleOpen = () => {
    reset();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const formatTimeRange = (date: Date | null) => {
    if (!date || isNaN(date.getTime())) return '';
    const start = format(date, 'yyyy-MM-dd HH:mm:ss');
    const end = format(new Date(date.getTime() + 60 * 60 * 1000), 'HH:mm:ss');
    return `${start} - ${end}`;
  };
  

  const onSubmit = async (data: FormData) => {
    const appointmentData = {
      driverFullName: data.driverFullName,
      driverPhoneNumber: data.driverPhoneNumber,
      serviceName: data.serviceName,
      service_fee: currency(data.service_fee).value,
      rebate_percentage: parseFloat(data.rebate_percentage),
      firstTimeRange: formatTimeRange(data.firstTimeRange),
      secondTimeRange: formatTimeRange(data.secondTimeRange),
      companyName: data.companyName,
      supplierShopName: data.supplierShopName,
      supplierStreet: '123 Main St',
      supplierCity: 'Anytown',
      supplierStateProvince: 'CA',
      supplierCountry: 'USA',
      supplierZipCode: '12345',
      vehicleColor: data.vehicleColor,
      vehicleYear: data.vehicleYear,
      vehicleMake: data.vehicleMake,
      vehicleModel: data.vehicleModel,
      vehiclePlate: data.vehiclePlate,
      vehicleCustomization: data.vehicleCustomization,
      to_phone_number: data.to_phone_number,
      appointment_status: 'pending',
      call_history: [],
    };
    try {
      const response = await fetch(`${API_URL}/api/v1/appointments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(appointmentData),
      });
      if (response.ok) {
        refreshAppointments();
        handleClose();
      } else {
        console.error('Failed to create appointment');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'inherit',
        boxShadow: 'none',
        color: 'black',
      }}
    >
      <Toolbar
        disableGutters
        sx={{ mt: '40px', mb: '16px', minHeight: 'auto !important' }}
      >
        <Box sx={{ flexGrow: 2, display: { xs: 'inherit', md: 'flex' } }}>
          <Typography
            noWrap
            variant="h4"
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'inherit', md: 'flex' },
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Maintenance Tasks Overview
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'inherit', md: 'flex' } }}>
          <Button
            variant={'contained'}
            size={'small'}
            color={'primary'}
            key="AddAppointment"
            onClick={handleOpen}
            endIcon={<MedicalServicesOutlined sx={{ mr: 1 }} />}
          >
            ADD NEW MAINTENANCE TASK
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create a new maintenance task</DialogTitle>
            <DialogContent dividers>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="driverFullName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Driver Full Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter full name'}
                      variant="standard"
                      label="Driver Full Name"
                      fullWidth
                      margin="normal"
                      error={!!errors.driverFullName}
                      helperText={errors.driverFullName?.message}
                    />
                  )}
                />
                <Controller
                  name="driverPhoneNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Driver Phone Number is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      variant="standard"
                      label="Driver Phone Number"
                      placeholder={'+1 (999) 999-9999'}
                      fullWidth
                      margin="normal"
                      error={!!errors.driverPhoneNumber}
                      helperText={errors.driverPhoneNumber?.message}
                    />
                  )}
                />
                <Controller
                  name="to_phone_number"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'To Phone Number is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      variant="standard"
                      label="Shop Phone Number"
                      placeholder={'+1 (999) 999-9999'}
                      fullWidth
                      margin="normal"
                      error={!!errors.to_phone_number}
                      helperText={errors.to_phone_number?.message}
                    />
                  )}
                />
                <Controller
                  name="serviceName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Service Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter service name'}
                      variant="standard"
                      label="Service Name"
                      fullWidth
                      margin="normal"
                      error={!!errors.serviceName}
                      helperText={errors.serviceName?.message}
                    />
                  )}
                />
                <Controller
                  name="service_fee"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Service Fee is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: { shrink: true },
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        },
                      }}
                      placeholder={'0,000.00'}
                      variant="standard"
                      label="Service Fee"
                      fullWidth
                      margin="normal"
                      error={!!errors.service_fee}
                      helperText={errors.service_fee?.message}
                    />
                  )}
                />
                <Controller
                  name="rebate_percentage"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Rebate Percentage is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: { shrink: true },
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        },
                      }}
                      placeholder={'00.00'}
                      variant="standard"
                      label="Rebate Percentage"
                      fullWidth
                      margin="normal"
                      error={!!errors.rebate_percentage}
                      helperText={errors.rebate_percentage?.message}
                    />
                  )}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="firstTimeRange"
                    control={control}
                    rules={{ required: 'First Time Range is required' }}
                    render={({ field: { onChange, value } }) => (
                      <DateTimePicker
                        label="First Time Range"
                        value={value}
                        onChange={onChange}
                        minutesStep={30}
                        slotProps={{
                          textField: {
                            placeholder: 'MM/DD/YYYY HH:mm',
                            InputLabelProps: { shrink: true },
                            error: !!errors.firstTimeRange,
                            helperText: errors.firstTimeRange?.message,
                          },
                        }}
                        sx={{ width: '100%', my: 2 }}
                      />
                    )}
                  />
                  <Controller
                    name="secondTimeRange"
                    control={control}
                    rules={{ required: 'Second Time Range is required' }}
                    render={({ field: { onChange, value } }) => (
                      <DateTimePicker
                        label="Second Time Range"
                        value={value}
                        onChange={onChange}
                        minutesStep={30}
                        slotProps={{
                          textField: {
                            placeholder: 'MM/DD/YYYY HH:mm',
                            InputLabelProps: { shrink: true },
                            error: !!errors.secondTimeRange,
                            helperText: errors.secondTimeRange?.message,
                          },
                        }}
                        sx={{ width: '100%', my: 2 }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Controller
                  name="companyName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Company Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter company name'}
                      variant="standard"
                      label="Company Name"
                      fullWidth
                      margin="normal"
                      error={!!errors.companyName}
                      helperText={errors.companyName?.message}
                    />
                  )}
                />
                <Controller
                  name="supplierShopName"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Supplier Shop Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter supplier shop name'}
                      variant="standard"
                      label="Supplier Shop Name"
                      fullWidth
                      margin="normal"
                      error={!!errors.supplierShopName}
                      helperText={errors.supplierShopName?.message}
                    />
                  )}
                />
                <Controller
                  name="vehicleColor"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Vehicle Color is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter vehicle color'}
                      variant="standard"
                      label="Vehicle Color"
                      fullWidth
                      margin="normal"
                      error={!!errors.vehicleColor}
                      helperText={errors.vehicleColor?.message}
                    />
                  )}
                />
                <Controller
                  name="vehicleYear"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Vehicle Year is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter vehicle year'}
                      variant="standard"
                      label="Vehicle Year"
                      fullWidth
                      margin="normal"
                      error={!!errors.vehicleYear}
                      helperText={errors.vehicleYear?.message}
                    />
                  )}
                />
                <Controller
                  name="vehicleMake"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Vehicle Make is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter vehicle make'}
                      variant="standard"
                      label="Vehicle Make"
                      fullWidth
                      margin="normal"
                      error={!!errors.vehicleMake}
                      helperText={errors.vehicleMake?.message}
                    />
                  )}
                />
                <Controller
                  name="vehicleModel"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Vehicle Model is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter vehicle model'}
                      variant="standard"
                      label="Vehicle Model"
                      fullWidth
                      margin="normal"
                      error={!!errors.vehicleModel}
                      helperText={errors.vehicleModel?.message}
                    />
                  )}
                />
                <Controller
                  name="vehiclePlate"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Vehicle Plate is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter vehicle plate'}
                      variant="standard"
                      label="Vehicle Plate"
                      fullWidth
                      margin="normal"
                      error={!!errors.vehiclePlate}
                      helperText={errors.vehiclePlate?.message}
                    />
                  )}
                />
                <Controller
                  name="vehicleCustomization"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Vehicle Customization is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{ inputLabel: { shrink: true } }}
                      placeholder={'Enter vehicle customization'}
                      variant="standard"
                      label="Vehicle Customization"
                      fullWidth
                      margin="normal"
                      error={!!errors.vehicleCustomization}
                      helperText={errors.vehicleCustomization?.message}
                    />
                  )}
                />
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Create Maintenance Task
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default OverviewBar;