import { Box, Paper, Stack, SxProps } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

const Section = ({
  children,
  sx,
  title,
  titleSx,
}: PropsWithChildren<{
  sx?: SxProps;
  title?: ReactNode;
  titleSx?: SxProps;
}>) => (
  <Paper elevation={1} sx={{ borderRadius: 3, backgroundColor: 'white' }}>
    <Stack sx={sx} gap={1} py={5} px={2}>
      {title && (
        <Box pl={5} pb={2} sx={titleSx}>
          {title}
        </Box>
      )}
      {children}
    </Stack>
  </Paper>
);

export default Section;
