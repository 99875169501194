import ResponsiveAppBar from 'components/app-bar/ResponsiveAppBar';
import { useAuth, useIsAuthenticated } from 'components/login/AuthProvider';
import Login from 'components/login/Login';
import AppointmentsProvider from 'providers/AppointmentsProvider';
import { Outlet } from 'react-router-dom';

const Root = () => {
  const { login } = useAuth();
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return (
      <Login
        onLogin={() => {
          login();
        }}
      />
    );
  }

  return (
    <AppointmentsProvider>
      <ResponsiveAppBar />
      <Outlet />
    </AppointmentsProvider>
  );
};

export default Root;
