import Section from 'components/section/Section';
import { ReactNode } from 'react';
import { format, isValid } from 'date-fns';
import { Appointment } from 'types';
import currency from 'currency.js';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber, parseDate } from 'utils';
import AppointmentStatusChip from 'components/appointment/AppointmentStatusChip';
import Table, { TableModel } from 'components/table/Table';
import { Box } from '@mui/material';
import CallButton from '../call-button/CallButton';
import CallOutcomeChip from '../appointment/CallOutcomeChip';
import { RESOLVED_APPOINTMENT_STATUSES } from '../../constants';

const AppointmentStatus = ({ item }: { item: Appointment }) => (
  <Box display="flex" alignItems="center" gap="25px">
    {item.appointment_status === 'pending' && item.call_history.length > 0 ? (
      <CallOutcomeChip
        callRecord={item.call_history[0]}
        width="160px"
      />
    ) : (
      <AppointmentStatusChip
        minWidth="160px"
        status={item.appointment_status}
        dueDate={item.appointment_time}
      />
    )}
    {!RESOLVED_APPOINTMENT_STATUSES.includes(item.appointment_status) && (
      <CallButton appointmentId={item.appointment_id} />
    )}
  </Box>
);

const AppointmentsSection = ({
  title,
  appointments,
}: {
  title: ReactNode;
  appointments: Appointment[];
}) => {
  const navigate = useNavigate();

  const tableModel: TableModel<Appointment> = {
    columns: [
      {
        width: '19%',
        label: 'Driver name',
        render: (item) => item.driverFullName,
      },
      {
        width: '19%',
        label: 'Driver phone number',
        render: (item) => (
          <Box component="span" sx={{ color: 'secondary.dark' }}>
            {formatPhoneNumber(item.driverPhoneNumber)}
          </Box>
        ),
      },
      {
        width: '19%',
        label: 'Service fee',
        render: (item) =>
          currency(item.service_fee, {
            precision: 2,
          }).format(),
      },
      {
        width: '19%',
        label: 'Appointment date',
        render: (item) => {
          if (!item.appointment_time) {
            return 'Not scheduled';
          }
          const parsedDate = parseDate(item.appointment_time);
          if (isValid(parsedDate)) {
            return format(parsedDate, 'MMMM dd');
          } else {
            console.error('Invalid date:', item.appointment_time);
            return 'Invalid date';
          }
        },
      },
      {
        width: '25%',
        label: 'Status',
        render: (item) => <AppointmentStatus item={item} />,
      },
    ],
  };

  return (
    <Section title={title}>
      <Table
        data={appointments}
        tableModel={tableModel}
        onRowClick={(item) => navigate(`/appointments/${item.appointment_id}`)}
      />
    </Section>
  );
};

export default AppointmentsSection;