import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

const context = createContext<{
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
}>({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => sessionStorage.getItem('isAuthenticated') === 'true'
  );

  const login = useCallback(() => {
    sessionStorage.setItem('isAuthenticated', 'true');
    setIsAuthenticated(true);
  }, []);

  const logout = useCallback(() => {
    sessionStorage.removeItem('isAuthenticated');
    setIsAuthenticated(false);
  }, []);

  return (
    <context.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </context.Provider>
  );
};

export const useIsAuthenticated = () => {
  return useContext(context).isAuthenticated;
};

export const useAuth = () => {
  const auth = useContext(context);
  return { login: auth.login, logout: auth.logout };
};

export default AuthProvider;
