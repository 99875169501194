import { Appointment } from 'types';
import Section from 'components/section/Section';
import { ReactNode, useMemo } from 'react';
import { Divider, Stack, SxProps, Typography } from '@mui/material';
import currency from 'currency.js';
import { cyan, green } from '@mui/material/colors';

const getAppointmentsSummary = (
  appointments: Appointment[],
  property: 'service_fee'
) => {
  const totalAmount = appointments.reduce(
    (acc, current) => acc.add(currency(current[property])),
    currency(0)
  );

  return {
    count: appointments.length,
    amount: currency(totalAmount, {
      separator: ',',
      decimal: '.',
      symbol: '',
    }).format(),
  };
};

const getCollectedAmount = (appointments: Appointment[]) => {
  const totalAmount = appointments.reduce((acc, current) => {
    if (
      current.appointment_status !== 'cancelled' &&
      (current.appointment_status === 'confirmed' ||
        current.appointment_status === 'rescheduled') &&
      new Date(current.appointment_time) < new Date()
    ) {
      return acc.add(currency(current.service_fee).multiply(current.rebate_percentage/100));
    }
    return acc;
  }, currency(0));

  return currency(totalAmount, {
    separator: ',',
    decimal: '.',
    symbol: '',
  }).format();
};

const BlockItem = ({
  label,
  value,
  sx,
}: {
  label: string;
  value: ReactNode;
  sx?: SxProps;
}) => (
  <Stack gap={2} alignItems="center" sx={sx}>
    <Typography variant="subtitle2">{label}</Typography>
    {value}
  </Stack>
);

const DashboardStrip = ({
  openAppointments,
  inProgressAppointments,
  closedAppointments,
}: {
  openAppointments: Appointment[];
  inProgressAppointments: Appointment[];
  closedAppointments: Appointment[];
}) => {
  const open = useMemo(
    () => getAppointmentsSummary([...openAppointments, ...inProgressAppointments], 'service_fee'),
    [openAppointments, inProgressAppointments]
  );
  const closed = useMemo(
    () => getAppointmentsSummary(closedAppointments, 'service_fee'),
    [closedAppointments]
  );
  const collectedAmount = useMemo(
    () => getCollectedAmount(closedAppointments),
    [closedAppointments]
  );

  return (
    <Section sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
      <BlockItem
        label="Maintenance Tasks Open"
        value={
          <Typography variant="h2" color={cyan['A700']}>
            {open.count}
          </Typography>
        }
        sx={{ flexGrow: 1 }}
      />
      <Divider orientation="vertical" flexItem />
      <BlockItem
        label="Total Service Fees"
        value={
          <Typography variant="h2" color={green['A700']}>
            {open.amount}
          </Typography>
        }
        sx={{ flexGrow: 2 }}
      />
      <Divider orientation="vertical" flexItem />
      <BlockItem
        label="Maintenance Tasks Closed"
        value={<Typography variant="h2">{closed.count}</Typography>}
        sx={{ flexGrow: 1 }}
      />
      <Divider orientation="vertical" flexItem />
      <BlockItem
        label="Amount Collected"
        value={<Typography variant="h2">{collectedAmount}</Typography>}
        sx={{ flexGrow: 2 }}
      />
    </Section>
  );
};

export default DashboardStrip;