import { LibraryBooks, PhoneCallback } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import Section from 'components/section/Section';
import Table, { TableModel } from 'components/table/Table';
import { format, isValid } from 'date-fns';
import { CallRecord } from 'types';
import CallOutcomeChip from './CallOutcomeChip';
import { useState } from 'react';
import Transcript from './Transcript';
import AudioRecordingButton from './AudioRecordingButton';
import { parseDate } from 'utils';

const CallHistory = ({ callRecords }: { callRecords: CallRecord[] }) => {
  const [transcript, setTranscript] = useState<string>();

  const closeTranscript = () => {
    setTranscript(undefined);
  };

  const tableModel: TableModel<CallRecord> = {
    columns: [
      {
        label: 'Date contacted',
        width: '30%',
        render: (item) => {
          try {
            const parsedDate = parseDate(item.call_time_utc);
            if (isValid(parsedDate)) {
              return format(parsedDate, 'MMMM dd, yyyy');
            } else {
              throw new Error('Invalid date');
            }
          } catch (error) {
            console.error(error);
            return item.call_time_utc;
          }
        },
      },
      {
        label: 'Transcript of the call',
        width: '25%',
        render: (item) => (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => setTranscript(item.call_transcript)}
            startIcon={<LibraryBooks />}
            sx={{ height: { xs: '100%', sm: 'auto' } }}
          >
            VIEW TRANSCRIPT
          </Button>
        ),
      },
      {
        label: 'Recording of the call',
        width: '25%',
        render: (item) => <AudioRecordingButton callId={item.call_id} />,
      },
      {
        label: 'Call outcome',
        width: '10%',
        render: (item) => <CallOutcomeChip callRecord={item} />,
      },
    ],
  };

  return (
    <>
      <Section
        title={
          <Box display="flex" gap={1} alignItems="center">
            <PhoneCallback sx={{ color: 'primary.dark' }} />
            <Typography variant="h5">Call history</Typography>
          </Box>
        }
      >
        <Table data={callRecords} tableModel={tableModel} />
      </Section>
      {Boolean(transcript) && (
        <Transcript transcript={transcript!} onClose={closeTranscript} />
      )}
    </>
  );
};

export default CallHistory;