import { Chip } from '@mui/material';
import { useMemo } from 'react';
import { CallRecord } from 'types';
import AppointmentStatusChip from './AppointmentStatusChip';

const CallOutcomeChip = ({
  callRecord,
  width = '100%',
}: {
  callRecord: CallRecord;
  width?: string;
}) => {
  const { color, label } = useMemo(() => {
    return {
      label: callRecord.disposition_tag,
      color: 'status.inProgress',
    };
  }, [callRecord]);

  if (!label) return null;

  return (
    <Chip
      label={label.replaceAll('_', ' ').toLowerCase()}
      sx={{
        backgroundColor: color,
        color: 'warning.contrastText',
        width: width,
      }}
    />
  );
};

export default CallOutcomeChip;
