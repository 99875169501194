import { Box, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import DashboardStrip from 'components/dashboard-strip/DashboardStrip';
import AppointmentsSection from 'components/dashboard/AppointmentsSection';
import { WorkOffOutlined, WorkOutline } from '@mui/icons-material';
import { blueGrey } from '@mui/material/colors';
import { useAppointments } from 'providers/AppointmentsProvider';
import OverviewBar from 'components/overview-bar/OverviewBar';
import { RESOLVED_APPOINTMENT_STATUSES } from '../../constants';

const Dashboard = () => {
  const { appointments: data, refreshAppointments } = useAppointments();

  const openAppointments = useMemo(
    () =>
      data.filter(
        ({ appointment_status, call_history }) =>
          ['pending'].includes(appointment_status)
      ),
    [data]
  );
  const inProgressAppointments = useMemo(
    () =>
      data.filter(
        ({ appointment_status, appointment_time }) =>
          RESOLVED_APPOINTMENT_STATUSES.includes(appointment_status) && new Date(appointment_time) > new Date()
      ),
    [data]
  );
  const closedAppointments = useMemo(
    () =>
      data.filter(({ appointment_status, appointment_time }) =>
        ['cancelled'].includes(appointment_status) || (RESOLVED_APPOINTMENT_STATUSES.includes(appointment_status) && new Date(appointment_time) <= new Date())
      ),
    [data]
  );

  useEffect(() => {
    const refreshInterval = setInterval(() => refreshAppointments(), 5000);
    return () => clearInterval(refreshInterval);
  }, [refreshAppointments]);

  return (
    <>
      <OverviewBar />
      <DashboardStrip
        openAppointments={openAppointments}
        closedAppointments={closedAppointments}
        inProgressAppointments={inProgressAppointments}
      />
      <AppointmentsSection
        title={
          <Box display="flex" gap={1} alignItems="center">
            <WorkOutline sx={{ color: 'primary.dark' }} />
            <Typography variant="h5">New Maintenance Tasks</Typography>
          </Box>
        }
        appointments={openAppointments}
      />
      <AppointmentsSection
        title={
          <Box display="flex" gap={1} alignItems="center">
            <WorkOutline sx={{ color: 'primary.dark' }} />
            <Typography variant="h5">In progress</Typography>
          </Box>
        }
        appointments={inProgressAppointments}
      />
      <AppointmentsSection
        title={
          <Box display="flex" gap={1} alignItems="center">
            <WorkOffOutlined sx={{ color: blueGrey[800] }} />
            <Typography variant="h5">Resolved Maintenance Tasks</Typography>
          </Box>
        }
        appointments={closedAppointments}
      />
    </>
  );
};

export default Dashboard;