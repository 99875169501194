import { CallOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { API_URL } from '../../constants';

const handleInitiateCall = (appointmentId: string) => {
  fetch(`${API_URL}/api/v1/calls`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ appointment_id: appointmentId }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('Call initiated:', data);
      // Handle the response data as needed
    })
    .catch((error) => {
      console.error('Error initiating call:', error);
      // Handle the error as needed
    });
};

const CallButton = ({ appointmentId }: { appointmentId: string }) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisabled(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [disabled]);

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      endIcon={<CallOutlined sx={{ mr: 1 }} />}
      sx={{ height: 'min-content' }}
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        setDisabled(true);
        handleInitiateCall(appointmentId);
      }}
    >
      INITIATE A CALL
    </Button>
  );
};

export default CallButton;