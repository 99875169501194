import { Speaker } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useState } from 'react';

const AudioRecordingButton = ({ callId }: { callId: string }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Box display="flex" alignItems="center">
      {!isPlaying && (
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<Speaker />}
          sx={{ height: { xs: '100%', sm: 'auto' } }}
          onClick={() => setIsPlaying(true)}
        >
          LISTEN TO A RECORDING
        </Button>
      )}
      {isPlaying && (
        <audio
          autoPlay
          controls
          src={`/api/v1/calls/${callId}/recording`}
          onEnded={() => {
            setIsPlaying(false);
          }}
        />
      )}
    </Box>
  );
};

export default AudioRecordingButton;
