import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {capitalize} from 'lodash'

const Transcript = ({
  transcript,
  onClose,
}: {
  transcript: string;
  onClose: () => void;
}) => {
  const transcriptLines = transcript
    .split('\n')
    .map((text) => text.trim())
    .filter((data) => data);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Transcript</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
      <DialogContent>
        {transcriptLines.map((line, index) => {
          const lineSplit = line.split(':');
          const speaker = lineSplit[0];
          const text = lineSplit[1];
          return (
            <Box key={index} mb={1}>
              <strong>{capitalize(speaker)}:</strong>
              {text}
            </Box>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default Transcript;
