import {
  SxProps,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

const TableHeader = ({
  children,
  width,
}: PropsWithChildren<{ width?: string }>) => (
  <TableCell align="left" width={width} sx={{ py: { xs: 1, sm: 0 } , minWidth: {xs: 'auto', md:' 258px', xl: 'auto'}} }>
    <Typography
      variant="listSubheader"
      sx={{
        lineHeight: (theme) => ({
          xs: 2,
          sm: theme.typography.listSubheader.lineHeight,
        }),
      }}
    >
      {children}
    </Typography>
  </TableCell>
);

const TableValue = ({
  children,
  sx,
  width,
}: PropsWithChildren<{ sx?: SxProps; width?: string }>) => (
  <TableCell align="left" sx={sx} width={width}>
    <Typography variant="inputValue"> {children}</Typography>
  </TableCell>
);

export type TableModel<T> = {
  columns: {
    width?: string;
    label: string;
    render: (item: T) => ReactNode;
  }[];
};

const Table = <T,>({
  data,
  tableModel,
  onRowClick,
}: {
  data: T[];
  tableModel: TableModel<T>;
  onRowClick?: (data: T) => void;
}) => {
  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          <TableRow>
            {tableModel.columns.map((column, index) => (
              <TableHeader key={index} width={column.width}>
                {column.label}
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>
        {data.length> 0 && <TableBody>
          {data.map((record, index) => (
            <TableRow
              key={`${index}`}
              hover
              onClick={
                onRowClick
                  ? () => {
                      onRowClick(record);
                    }
                  : undefined
              }
              sx={
                onRowClick
                  ? {
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }
                  : undefined
              }
            >
              {tableModel.columns.map((column, dataIndex) => (
                <TableValue key={dataIndex} width={column.width}>
                  {column.render(record)}
                </TableValue>
              ))}
            </TableRow>
          ))}
        </TableBody>}
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
