import { Box, Typography } from '@mui/material';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useParams,
  useRevalidator,
} from 'react-router-dom';
import { Appointment } from 'types';
import AppointmentDetailsHeader from './AppointmentDetailsHeader';
import CallHistory from './CallHistory';
import { API_URL } from '../../constants';
import CallButton from 'components/call-button/CallButton';
import { useEffect } from 'react';

export const appointmentLoader = ({ params }: LoaderFunctionArgs) => {
  return fetch(`${API_URL}/api/v1/appointments/${params.id}`).then((resp) =>
    resp.json()
  );
};

const AppointmentDetails = () => {
  const appointmentId = useParams().id!;
  const appointmentDetails = useLoaderData() as Appointment;

  const revalidator = useRevalidator();

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      revalidator.revalidate();
    }, 5000);
    return () => clearInterval(refreshInterval);
  }, [revalidator]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        rowGap="10px"
        mt="40px"
        mb="16px"
      >
        <Box display="flex" gap={2} alignItems="center">
          <Typography variant="h4">Appointment details</Typography>
        </Box>
        {appointmentDetails.appointment_status === 'pending' ? (
          <CallButton appointmentId={appointmentId} />
        ) : null}
      </Box>
      <AppointmentDetailsHeader appointmentDetails={appointmentDetails} />
      <CallHistory
        callRecords={appointmentDetails.call_history}
      />
    </>
  );
};

export default AppointmentDetails;