import axios from 'axios';
import { API_URL } from '../constants';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Appointment } from 'types';

const appointmentsContext = createContext<{
  appointments: Appointment[];
  refreshAppointments: () => Promise<void>;
}>({ appointments: [], refreshAppointments: () => Promise.resolve() });

const AppointmentsProvider = ({ children }: PropsWithChildren) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const fetchAppointments = useCallback(
    () =>
      axios
        .get<{ [key: string]: Omit<Appointment, 'id'> }>(`${API_URL}/api/v1/appointments`)
        .then((response) => {
          const records: Appointment[] = Object.entries(response.data).map(
            ([entry, value]) => ({ id: entry, ...value })
          );
          setAppointments(records);
        })
        .catch((error) => {
          console.error('There was an error fetching the data!', error);
        }),
    []
  );

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  return (
    <appointmentsContext.Provider value={{ appointments, refreshAppointments: fetchAppointments }}>
      {children}
    </appointmentsContext.Provider>
  );
};

export const useAppointments = () => {
  return useContext(appointmentsContext);
};

export default AppointmentsProvider;