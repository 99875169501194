import { createTheme, responsiveFontSizes } from '@mui/material';
import {
  blue,
  blueGrey,
  green,
  indigo,
  orange,
  purple,
} from '@mui/material/colors';

export let theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
      dark: blue[600],
    },
    secondary: {
      main: purple[500],
      dark: purple[700],
    },
    text: {
      primary: '#000000DE',
    },
    warning: {
      light: orange[500],
      main: orange[800],
      dark: orange[900],
    },
    status: {
      inProgress: blueGrey[900],
      paymentPlan: green[500],
      fullyPaid: green[900],
    },
    background: { default: '#F1F3F5' },
  },

  typography: {
    fontFamily: 'Roboto, sans-serif',
    listSubheader: {
      fontFamily: 'Roboto',
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 3.42857,
      letterSpacing: '0.00625rem',
    },
    inputValue: {
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 1.5,
      letterSpacing: '0.00938rem',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState: { variant, color, disabled } }) => {
          const isPrimaryContainer =
            variant === 'contained' && color === 'primary' && !disabled;
          return {
            background: isPrimaryContainer
              ? `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`
              : undefined,
            boxShadow: isPrimaryContainer
              ? '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20) !important'
              : undefined,
          };
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '21px',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0 8px',
          border: 'none',
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            backgroundColor: '#F8F9FA',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          padding: '0',
          '&.MuiTableCell-alignLeft': {
            paddingRight: '10px',
          },

          '&:first-of-type': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            '&.MuiTableCell-alignLeft': {
              paddingLeft: '40px',
            },
          },
          '&:last-of-type': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            paddingRight: '40px',
          },
          '&.MuiTableCell-body': {
            border: 'none',
            height: '64px',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            backgroundColor: indigo[50],
          },
          '& .MuiTableCell-head': {
            verticalAlign: 'top',
            border: 'none',
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);
