import { isValid } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';

export const formatPhoneNumber = (phoneNumber: string) => {
  try {
    return parsePhoneNumber(phoneNumber).formatInternational();
  } catch (error) {
    return phoneNumber;
  }
};

export const parseDate = (date: string) => {
  const parsedDate = new Date(date);
  if (isValid(parsedDate)) {
    return parsedDate;
  }
  return new Date(date);
};