import { Chip } from '@mui/material';
import { differenceInDays, startOfDay, startOfToday } from 'date-fns';
import { AppointmentStatus } from 'types';
import { parseDate } from 'utils';

const AppointmentStatusChip = ({
  status,
  dueDate,
  minWidth = '120px',
}: {
  status: AppointmentStatus;
  dueDate?: string;
  minWidth?: string;
}) => {
  if (status === 'confirmed')
    return (
      <Chip
        label="confirmed"
        sx={{
          backgroundColor: 'success.main', // corrected color
          color: 'success.contrastText', // corrected color
          minWidth,
        }}
      />
    );

  if (status === 'rescheduled')
    return (
      <Chip
        label="rescheduled"
        sx={{
          backgroundColor: 'info.main', // corrected color
          color: 'info.contrastText', // corrected color
          minWidth,
        }}
      />
    );

  if (status === 'cancelled')
    return (
      <Chip
        label="cancelled"
        sx={{
          backgroundColor: 'error.main',
          color: 'error.contrastText',
          minWidth,
        }}
      />
    );

  if (!dueDate) return null;
  const daysUntilDue = differenceInDays(
    startOfDay(parseDate(dueDate)),
    startOfToday()
  );

  let label = '';
  let backgroundColor = 'warning.light';
  if (daysUntilDue >= 0) {
    label = `only ${daysUntilDue} days left`;
    if (daysUntilDue <= 7) {
      backgroundColor = 'warning.main';
    }
    if (daysUntilDue <= 3) {
      backgroundColor = 'warning.dark';
    }
  }
  if (daysUntilDue === 1) {
    label = `only 1 day left`;
  }
  if (daysUntilDue === 0) {
    label = 'due today';
  }
  if (daysUntilDue === -1) {
    label = '1 day past due';
  }
  if (daysUntilDue <= -1) {
    label = `${-daysUntilDue} days past due`;
  }

  return (
    <Chip
      label={label}
      color="warning"
      sx={{ color: 'warning.contrastText', backgroundColor, minWidth }}
    />
  );
};

export default AppointmentStatusChip;
